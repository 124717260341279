// React
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// Utils
import { getNavData } from '../../utils/NavigationUtil';
import { checkIfUserLoggedIn } from '../../components/Protected/AuthHandler';
import { getUserDetails } from '../../utils/AuthUtil';
import { appRoles } from '../../utils/Constants';
import { Sidetab } from '@maersk-global/insightshub-embed-react';

// Contexts and reducers
import { AppContext } from '../../contextProviders/AppContextProvider';
import UnsavedChangesModal from '../../components/Modal/unsavedPrompt';
import { useAppDispatch } from '../../store/store';
import { resetQuestions } from '../../store/slice/questionSlice';
import { resetSections } from '../../store/slice/sectionSlice';
import { addUpdateUser } from '../../store/slice/userSlice';
import env from 'react-dotenv';

const { SUS_FORM_ID: SUS_ID } = env;
export const SideNav: React.FC<any> = (props) => {
  const navData = getNavData();
  const allTabsName = navData.map((ele) => ele.link.split('/').splice(1)[0]);
  const [navList, setNavList] = useState<any>([]);
  const [isUnsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);
  const [intendedLink, setIntendedLink] = useState<string | null>(null);
  const emailId = useRef('');
  const userName = useRef('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const context = useContext(AppContext);
  // Check if context is undefined
  if (!context) {
    throw new Error("undefined context");
  }
  // To get the unsaved changes status from the App context
  const { unsavedChanges } = context;

  useEffect(() => {
    const getUserRole = async () => {
      const userDetails = await getUserDetails();
      emailId.current = userDetails?.email ?? '';
      userName.current = userDetails?.name ?? '';
      const role = userDetails?.roles?.[0];
      dispatch(addUpdateUser({ userName: userName.current, email: emailId.current, role: role ?? '' }));
      if (role === undefined || role === null) {
        setNavList([]);
        return;
      }
      if (role === appRoles?.Admin || userDetails?.roles?.includes(appRoles?.Admin)) {
        setNavList(navData);
      }
      else if (role === appRoles?.Vessel || role === appRoles?.Inspector) {
        setNavList(navData?.filter((ele) => ele?.id === 0 || ele?.id === 1));
      }

      else {
        setNavList([]);
      }
    }
    getUserRole();
  }, []);
  const location = useLocation();

  function getCurrentPageName() {
    const currentPage = location.pathname.split('/').splice(1)[0];
    // Check if the current page is one of the main tabs
    if (allTabsName.includes(currentPage)) {
      return currentPage;
    }

    // Check if the current page is within any subNav of the tabs
    for (const item of navList) {
      if (item.subNav && item.subNav.length > 0) {
        for (const subNavItem of item.subNav) {
          if (subNavItem.link.split('/').splice(1)[0] === currentPage) {
            return item.link.split('/').splice(1)[0]; // Return the parent tab's link
          }
        }
      }
    }
    // If not found in main tabs or subNavs, default to 'dashboard'
    return 'dashboard';
  }

  const [focusNav, setFocusNav] = useState(getCurrentPageName());

  const isLoggedIn = checkIfUserLoggedIn();

  const toggleUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(!isUnsavedChangesModalOpen);
  };

  const handleNavLinkClick = (event: any, link: any) => {
    if (unsavedChanges) {
      // Prevent the default behavior (page reload) if the user cancels the navigation
      event.preventDefault();
      // Open the unsaved changes modal
      toggleUnsavedChangesModal();
      // Store the intended link
      setIntendedLink(link);
    } else {
      // Update the focusNav and navigate to the clicked link
      setFocusNav(link?.split('/')?.splice(1)?.[0]);
      navigate(link);
    }
  };

  const handleDiscardChanges = (discard: boolean) => {
    try {
      // Close the modal
      toggleUnsavedChangesModal();

      if (discard) {
        // Reset the store data of questions and sections
        dispatch(resetQuestions())
        dispatch(resetSections())

        // Navigate back or to the intended link only if 'Yes, Discard' is clicked
        if (intendedLink) {
          // Update the focusNav and navigate to the clicked link
          setFocusNav(intendedLink?.split('/')?.splice(1)?.[0]);
          navigate(intendedLink);
        }
      }
    } catch (error) {
      console.error('Error discarding changes', error);
    }
  };

  return (
    <div className={`sidenavClosed ${!isLoggedIn ? 'remove-side-nav' : ''}`}>
      <a href='/' className='logo-home-sidenav'>
        <img src='maersk-star.svg' alt='maersk logo' />
      </a>
      {/* Common parent element for NavLink and Modal */}
      <div>
        {navList?.map((item: any, idx: number) => (
          <NavLink
            key={item?.id}
            className={`sideitem close-panel-hover ${focusNav === item?.link?.split('/')?.splice(1)?.[0] ? 'current-nav-item' : ''}`}
            to={item?.link === '#' || item?.link === '/' ? '/dashboard' : item?.link}
            onClick={(event) => handleNavLinkClick(event, item?.link)}
          >
            <span
              className={`icon-prime ${focusNav === item?.link?.split('/')?.splice(1)?.[0] ? 'current-nav-icon-hide' : ''}`}
            >
              {item?.icon}
            </span>
            <span
              className={`icon-hover ${focusNav === item?.link?.split('/')?.splice(1)?.[0] ? 'current-nav-icon-show' : ''}`}
            >
              {item?.hoverIcon}
            </span>
            <span className={`${focusNav === item?.link?.split('/')?.splice(1)?.[0] ? 'current-nav-linktext' : 'linkText'}`}>
              {item?.text}
            </span>
          </NavLink>
        ))}

        {/* Unsaved Changes Confirmation Modal */}
        <UnsavedChangesModal
          show={isUnsavedChangesModalOpen}
          onHide={toggleUnsavedChangesModal}
          handleDiscardChanges={handleDiscardChanges}
        />
      </div>
      <Sidetab id={SUS_ID}
        buttonText='Open Feedback'
        email={emailId.current}
      />
    </div>
  );
};