export class UserLoginResponse {
    Name!: number;
    Email!: number;
    ContactNumber!: number;
    IsLoggedIn!: boolean;
    Roles!: string[];
    Errors!: any
}

export class LoggedInUserModel {
    name?: string;
    email?: string;
    isLoggedIn?: boolean;
    roles?: string[];
}