import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SurveySectionAtteendees } from '../../pages/PerformInspection/model';

interface ISection {
    sectionId: number;
    sectionName: string;
    notes?: string;
    attendees?: SurveySectionAtteendees[];
    unsavedChanges: boolean;
}

interface ISections {
    sections: ISection[];
}

const initialState: ISections = {
    sections: []
};

export const SectionSlice = createSlice({
    name: 'sectionDetails',
    initialState: initialState,
    reducers: {
        resetSections: () => initialState,
        addSection: (state, action: PayloadAction<ISection>) => {
            // Check if a section with the same ID already exists
            const existingSection = state.sections.find(section => section.sectionId === action.payload.sectionId);
            if (!existingSection) {
                // If no existing section found, add the new section
                state.sections.push({
                    sectionId: action.payload.sectionId,
                    sectionName: action.payload.sectionName,
                    unsavedChanges: action.payload.unsavedChanges,
                    notes: action.payload.notes || '',
                    attendees: action.payload.attendees || []
                });
            } else {
                // If section with the same ID already exists, update its properties
                existingSection.sectionName = action.payload.sectionName;
                existingSection.unsavedChanges = action.payload.unsavedChanges;
                existingSection.notes = action.payload.notes || '';
                existingSection.attendees = action.payload.attendees || [];
            }
        },
        // Update the unsaved changes status of a section
        updateUnsavedChangesWithSectionId: (state, action: PayloadAction<{ sectionId: number, unsavedChanges: boolean }>) => {
            const sectionIndex = state.sections.findIndex(section => section.sectionId === action.payload.sectionId);
            if (sectionIndex !== -1) {
                state.sections[sectionIndex].unsavedChanges = action.payload.unsavedChanges;
            }
        },
        // Update the notes of a section
        updateNotesWithSectionId: (state, action: PayloadAction<{ sectionId: number, notes: string }>) => {
            const sectionIndex = state.sections.findIndex(section => section.sectionId === action.payload.sectionId);
            if (sectionIndex !== -1) {
                state.sections[sectionIndex].notes = action.payload.notes;
            }
        },

        addAttendeeToSection: (state, action: PayloadAction<{ sectionId: number, attendee: SurveySectionAtteendees[] }>) => {
            const sectionIndex = state.sections.findIndex(section => section.sectionId === action.payload.sectionId);
            console.log('action.payload.attendee', action.payload.attendee);
            if (sectionIndex !== -1) {
                state.sections[sectionIndex].attendees = action.payload.attendee;
            }
        },
            
    }
});
export default SectionSlice.reducer;

export const { resetSections, addSection, updateUnsavedChangesWithSectionId, updateNotesWithSectionId,addAttendeeToSection } = SectionSlice.actions;