
export const keyPrefix = `${process.env.REACT_APP_AUTHORITY}.${process.env.REACT_APP_CLIENT_ID}`;

export const checkIfUserLoggedIn = (): boolean => {
  // skip login process while dev on local
  const skipLogin = process.env.REACT_APP_NOLOGIN ? process.env.REACT_APP_NOLOGIN : '1';
  if(parseInt(skipLogin)) {
    return true;
  }
  const tokenExpiry = localStorage.getItem(`al.${keyPrefix}.expiry_date`);
  const currentTimeStamp = new Date().getTime();

  if (tokenExpiry && parseInt(tokenExpiry) > currentTimeStamp) {
    return true;
  } else {
    return false;
  }
};

export const logoutApp = () => {
    localStorage.removeItem(`al.${keyPrefix}.expiry_date`);
    localStorage.removeItem(`al.${keyPrefix}.access_token`);
    localStorage.removeItem(`al.${keyPrefix}.id_token`);
    localStorage.removeItem('user_details');
    localStorage.removeItem('frJwt');
    localStorage.removeItem('logCount');
};

export const setUserData = (userData: any) => {
  // check if API result is already present
  if(localStorage.getItem('user_detaisl')) {

    localStorage.removeItem('user_details');
    localStorage.setItem('user_details', JSON.stringify(userData));
  } else {
    localStorage.setItem('user_details', JSON.stringify(userData));
  }
}
