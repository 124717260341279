import { AL_LoginRedirect } from '../../auth/al-login-callback-config';
import { UserAgentPublicApp } from "@maersk/iam-al";
import { loginCallbackConfig } from '../../auth/al-login-callback-config'
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper'

export const LoginCallback = () => {
    const al = new UserAgentPublicApp(loginCallbackConfig);
    return(
        <div className='container-fluid' style={{marginTop: 280, textAlign: 'center'}}>
            <McLoadingIndicator label='Waiting for authentication' fit='large'></McLoadingIndicator>
        </div>

    );
}