import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IUser {
    userName: string;
    email: string;
    role: string;
}

const initialState: IUser = {
    userName: '',
    email: '',
    role: '',
};

export const UserSlice = createSlice({
    name: 'userDetails',
    initialState: initialState,
    reducers: {
        resetUser: () => initialState,
        addUpdateUser: (state, action: PayloadAction<IUser>) => {
            state.userName = action.payload.userName;
            state.email = action.payload.email;
            state.role = action.payload.role;
        }
    }
});
export default UserSlice.reducer;

export const { resetUser, addUpdateUser} = UserSlice.actions;