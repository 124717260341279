import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { LocationModel} from '../utils/Constants';
import { GetSurveySectionLocations } from '../services/SurveyService';

interface ILocationContextProps {
    children: ReactNode;
}

type TLocationContextValue = LocationModel[];

const LocationContext = createContext<TLocationContextValue | undefined>(undefined);

export const LocationProvider: React.FC<ILocationContextProps> = ({ children }) => {
  const [locationList, setLocationList] = useState<LocationModel[]>([]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationData = await GetSurveySectionLocations();
        if (locationData) {
          setLocationList(locationData.locationModel);
        }
        else {
          setLocationList([]);
        }
      } catch (error) {
        setLocationList([]);
      }
    };

    fetchLocationData();
  }, []);

  return (
    <LocationContext.Provider value={locationList}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationData = (): TLocationContextValue => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error('useLocationData must be used within a LocationProvider');
  }
  return context;
};
