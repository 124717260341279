import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers} from "@reduxjs/toolkit";
import { SectionSlice } from "./slice/sectionSlice";
import { QuestionSlice } from "./slice/questionSlice";
import { UserSlice } from "./slice/userSlice";
import { RiskTabSlice } from "./slice/riskSlice";

// Define persist config
const persistConfig = {
    key: "persist-key",
    storage,
  };

// Combine reducers
const rootReducer = combineReducers({
    //Add your reducers here
    sections: SectionSlice.reducer,
    questions: QuestionSlice.reducer,
    user: UserSlice.reducer,
    risks: RiskTabSlice.reducer
})

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create the Redux store
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignore these actions for serializable data check
      },
    }), 
})

// Create the Redux persistor
export const persistor = persistStore(store);

// Custom hooks for useDispatch and useSelector 
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;

// For testing purposes
export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']