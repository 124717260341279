import { IApiResponse } from "../../utils/Constants";

export class VesselViewResponseModel {
  Count!: number;
  NoOfHighRiskVessels!: number;
  NoOfOverdueRisks!: number;
  NoOfPendingRequests!: number;
  TotalPages!: number;
  ItemsPerPage!: number;
  Page!: number;
  Fleet!: VesselDetailModel[];
}
export class VesseListApiResponseModel {
  Errors!: any;
  HttpStatusCode!: number;
  Pager!: Pager;
  Vessels!: VesselViewResponseModel;
  constructor() {
    this.Errors = null;
    this.HttpStatusCode = 0;
    this.Pager = new Pager();
    this.Vessels = new VesselViewResponseModel();
  }
}
export class Pager {
  CurrentPage!: number;
  PageSize!: number;
  TotalPages!: number;
  StartPage!: number;
  EndPage!: number;
  StartIndex!: number;
  EndIndex!: number;
  Pages!: number[];
}

export class VesselDetailModel {
  ItemsPerPage!: number;
  Page!: number;
  SortColumn!: number;
  SortDirection!: number;
  Dates!: any;
  Imo!: string | null;
  ShipName!: string;
  ShipManager!: string;
  CommercialOwner!: string;
  DateOfBuild!: string;
  Cranes!: number;

  LastInspectionDate!: any;
  LastInspectionDateStart!: any;
  LastInspectionDateEnd!: any;
  Firewall!: string;
  VesselScore!: string;

  NoOfHighRiskVessels!: number;
  NoOfOverdueRisks!: number;
  NoOfPendingRequests!: number;

  NoDefectsCorrected!: number;
  NoDaysDetainedCorrected!: number;

  QuestionnaireHigh!: number;
  QuestionnaireMedium!: number;
  QuestionnaireLow!: number;
  QuestionnaireRobi!: number;

  SurveyHigh!: number;
  SurveyMedium!: number;
  SurveyLow!: number;
  SurveyRobi!: number;

  RiskMatrix!: number;

  ReferenceFleet!: boolean;
  Email!: string | null;
  CommentCount!: number;

  Pages!: number;

  // Properties for Autoselection of vessles for survey

  EstimatedRedeliveryDate!: any;
  EstimatedRedeliveryDateStart!: any;
  EstimatedRedeliveryDateEnd!: any;

  LastSurveyScore!: string | null;

  HasDetention!: boolean;

  EstimatedRedeliveryDateInNext3Months!: boolean;
  NotInspectedIn11Months!: boolean;
  HighRiskNotInspectedIn6Months!: boolean;
  HasDetentionInLast1Month!: boolean;
  DeclinedAutoselectionForSurvey!: boolean;
  DeclinedAutoselectionForSurveyDate!: any;

  NotInspectedIn11MonthsSearch!: string | null;
  HighRiskNotInspectedIn6MonthsSearch!: string | null;
  HasDetentionSearch!: string | null;
  DeclinedAutoselectionForSurveySearch!: string | null;

  Reason!: string | null;
  DeclinedBy!: string | null;
  DeclinedByEmail!: string | null;

  CanSendToCommercialOwner!: boolean;
  CanSendToTechnicalManager!: boolean;

  SurveyRequestStatusId!: number;
  SurveyTitle!: string | null;
  LastSentStatus!: string | null;
  LastSentDate!: any;

  SurveyRequestStatusIdCrane!: number;
  SurveyTitleCrane!: string | null;
  LastSentStatusCrane!: string | null;
  LastSentDateCrane!: any;
  constructor() {
    this.ItemsPerPage = 25;
    this.Page = 1;
    this.SortColumn = 1;
    this.SortDirection = 1;
    this.Dates = null;
    this.Imo = null;
    this.ShipName = '';
    this.ShipManager = '';
    this.CommercialOwner = '';
    this.DateOfBuild = '';
    this.Cranes = 0;

    this.LastInspectionDate = null;
    this.LastInspectionDateStart = null;
    this.LastInspectionDateEnd = null;
    this.Firewall = '';
    this.VesselScore = '';

    this.NoOfHighRiskVessels = 0;
    this.NoOfOverdueRisks = 0;
    this.NoOfPendingRequests = 0;

    this.NoDefectsCorrected = 0;
    this.NoDaysDetainedCorrected = 0;

    this.QuestionnaireHigh = 0;
    this.QuestionnaireMedium = 0;
    this.QuestionnaireLow = 0;
    this.QuestionnaireRobi = 0;

    this.SurveyHigh = 0;
    this.SurveyMedium = 0;
    this.SurveyLow = 0;
    this.SurveyRobi = 0;

    this.RiskMatrix = 0;

    this.ReferenceFleet = false;
    this.Email = '';
    this.CommentCount = 0;

    this.Pages = 0;

    // Properties for Autoselection of vessles for survey

    this.EstimatedRedeliveryDate = null;
    this.EstimatedRedeliveryDateStart = null;
    this.EstimatedRedeliveryDateEnd = null;

    this.LastSurveyScore = null;

    this.HasDetention = false;

    this.EstimatedRedeliveryDateInNext3Months = false;
    this.NotInspectedIn11Months = false;
    this.HighRiskNotInspectedIn6Months = false;
    this.HasDetentionInLast1Month = false;
    this.DeclinedAutoselectionForSurvey = false;
    this.DeclinedAutoselectionForSurveyDate = null;

    this.NotInspectedIn11MonthsSearch = null;
    this.HighRiskNotInspectedIn6MonthsSearch = null;
    this.HasDetentionSearch = null;
    this.DeclinedAutoselectionForSurveySearch = null;

    this.Reason = null;
    this.DeclinedBy = null;
    this.DeclinedByEmail = null;

    this.CanSendToCommercialOwner = false;
    this.CanSendToTechnicalManager = false;

    this.SurveyRequestStatusId = 0;
    this.SurveyTitle = null;
    this.LastSentStatus = null;
    this.LastSentDate = null;

    this.SurveyRequestStatusIdCrane = 0;
    this.SurveyTitleCrane = null;
    this.LastSentStatusCrane = null;
    this.LastSentDateCrane = null;
  }
}

export class VesselListModel {
  Id!: number;
  Imo!: string;
  ShipName!: string;
  ShipManager!: string;
  CommercialOwner!: string;
  DateOfBuild!: string;

  LastInspectionDate!: string;
  Firewall!: string;
  VesselScore!: string;
}

export class OwnVesselApiResponse {
  ownVesselSearchModelResponse!: OwnVesselDataModel;
  Pager: any;
  HttpStatusCode!: number;
  Errors!: any;
  constructor() {
    this.ownVesselSearchModelResponse = new OwnVesselDataModel();
    this.Pager = null;
    this.HttpStatusCode = 404;
    this.Errors = null;
  }
}

export class OwnVesselDataModel {
  Count!: number;
  FilterId!: number;
  TotalPages!: number;
  ItemsPerPage!: number;
  Page!: number;
  ownVesselRes!: OwnVesselListModel[];
  constructor() {
    this.Count = 0;
    this.FilterId = 0;
    this.TotalPages = 0;
    this.ItemsPerPage = 0;
    this.Page = 0;
    this.ownVesselRes = [new OwnVesselListModel()];
  }
}
export class OwnVesselListModel {
  ItemsPerPage!: number;
  Page!: number;
  SortColumn!: number;
  SortDirection!: number;
  Pages!: number;
  Imo!: number;
  VesselName!: string;
  FleetGroup!: string;
  Superintendent!: string;
  LastInspection!: string;
  Build!: number;
  Inspected!: boolean;
  Detained!: boolean;
  HighRisk!: boolean;
  Rating!: number;
  DueDate!: string;
  NextDryDockDate!: string;
  PlannedDate!: string;
  Surveyor!: string;
  SurveyStatus!: string;

  constructor() {
    this.ItemsPerPage = 0;
    this.Page = 0;
    this.SortColumn = 0;
    this.SortDirection = 0;
    this.Pages = 0;
    this.Imo = 0;
    this.VesselName = '';
    this.FleetGroup = '';
    this.Superintendent = '';
    this.LastInspection = '';
    this.Build = 0;
    this.Inspected = false;
    this.Detained = false;
    this.HighRisk = false;
    this.Rating = 0;
    this.DueDate = '-';
    this.PlannedDate = '-';
    this.NextDryDockDate = '-';
    this.Surveyor = '-'
    this.SurveyStatus = '-';
  }
}

export class OwnVesselListViewModel {
  Imo!: string;
  VesselName!: string;
  FleetGroup!: string;
  Superintendent!: string;
  LastInspection!: any;
  Build!: string;
  Inspected!: boolean;
  Detained!: boolean;
  HighRisk!: boolean;
  Rating!: string;
  DueDate!: string;
  NextDryDockDate!: string;
  Surveyor!: string;
  PlannedDate!: string;
  SurveyStatus!: string;
  constructor() {
    this.Imo = '';
    this.VesselName = '';
    this.FleetGroup = '';
    this.Superintendent = '';
    this.LastInspection = null;
    this.Build = '';
    this.Inspected = false;
    this.Detained = false;
    this.HighRisk = false;
    this.Rating = '';
    this.DueDate = '-';
    this.Surveyor = '-'
    this.PlannedDate = '-';
    this.NextDryDockDate = '-';
    this.SurveyStatus = '-';
  }
}

export class SaveFilterResponse implements IApiResponse {
  success: boolean;
  message: string;
  statusCode: number;

  constructor(success: boolean, message: string, statusCode: number) {
    this.success = success;
    this.message = message;
    this.statusCode = statusCode;
  }
}

export class FilterResponseModel {
  FilterId: number;
  FilterName: string;

  constructor(FilterId: number, FilterName: string) {
    this.FilterId = FilterId;
    this.FilterName = FilterName;
  }
}
