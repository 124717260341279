import './TopNav.scss';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { logoutApp } from '../../components/Protected/AuthHandler';
import { useMsal } from "@azure/msal-react";
import { IT_HELP_URL } from '../../utils/Constants';
import LogoutModal from '../../components/Modal/logoutPrompt';

export const TopNav: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [showModal, setShowModal] = useState(false);
  const activeAccount = instance?.getActiveAccount();

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: activeAccount,
    });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const logoutHandler = () => {
    logoutApp();
    navigate('/login');
  };

  return (
    <div className='topbar'>
      <div className='top-bar-container'>
        <a href='' className='logo-home'>
          <img src='maersk-star.svg' alt='maersk logo' />
          <div className='logo-text'>Vessel Assurance</div>
        </a>
        {activeAccount && <div className="nav-container"></div>}
        <div className="utility-container">
          <div className='it-help-link-container'>
            <a href={IT_HELP_URL} target="_blank" rel="noopener noreferrer" className="it-help-link">
              <img src='/svg/icons/mds-headset-20px.svg' alt="it help icon" className="it-help-icon" />
              IT help
            </a>
          </div>
          <div className="user-info">
            <button className="logout-button" onClick={toggleModal}>
              <img src='/svg/icons/mds-door-arrow-right-20px.svg' alt="logout icon" className="logout-icon" />
              <span className="currentusername">
                {activeAccount ? activeAccount.name : ""}
              </span>
            </button>
          </div>
        </div>
      </div>
      {showModal && <LogoutModal showModal={showModal} onClose={toggleModal} onConfirm={handleLogoutRedirect} />}
    </div>
  );
};

export default TopNav;