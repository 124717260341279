import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IQuestion {
    uniqueKey?: string;
    questionId: number;
    questionName: string;
    questionType: string;
    comments?: string;
    response?: string;
    unsavedChanges: boolean;
    questionnaireId?: number;
    questionSectionId?: number;
    questionnaireName?: string;
}

interface IQuestions{
    questions: IQuestion[];
}

const initialState: IQuestions = {
    questions: []
};

export const QuestionSlice = createSlice({
    name: 'questionDetails',
    initialState: initialState,
    reducers: {
        resetQuestions: () => initialState,
        addQuestion: (state, action: PayloadAction<IQuestion>) => {
            // Generate uniqueKey
            const uniqueKey = `${action.payload.questionnaireId}-${action.payload.questionSectionId}-${action.payload.questionId}`;

            // Check if a question with the same uniqueKey already exists
            const existingQuestion = state.questions.find(question => question.uniqueKey === uniqueKey);
            if (!existingQuestion) {
                // If no existing question found, add the new question
                state.questions.push({
                    ...action.payload,
                    uniqueKey: uniqueKey
                });
            } else {
                // If question with the same uniqueKey already exists, update its properties
                existingQuestion.questionName = action.payload.questionName;
                existingQuestion.unsavedChanges = action.payload.unsavedChanges;
                existingQuestion.comments = action.payload.comments;
                existingQuestion.response = action.payload.response;
                existingQuestion.questionnaireId = action.payload.questionnaireId;
                existingQuestion.questionSectionId = action.payload.questionSectionId;
                existingQuestion.questionnaireName = action.payload.questionnaireName;
            }
        },
        // Update the unsaved changes status of a question
        updateUnsavedChangesWithQuestionId: (state, action: PayloadAction<{ questionId: number, questionSectionId: number, questionnaireId:number, unsavedChanges: boolean }>) => {
            const uniqueKey = `${action.payload.questionnaireId}-${action.payload.questionSectionId}-${action.payload.questionId}`;
            const question = state.questions.find(question => question.uniqueKey === uniqueKey);
            if (question) {
                question.unsavedChanges = action.payload.unsavedChanges;
            }
        },
        // Update the comments of a question
        updateCommentsWithQuestionId: (state, action: PayloadAction<{ questionId: number, questionSectionId: number, questionnaireId:number, comments: string }>) => {
            const uniqueKey = `${action.payload.questionnaireId}-${action.payload.questionSectionId}-${action.payload.questionId}`;
            const question = state.questions.find(question => question.uniqueKey === uniqueKey);
            if (question) {
                question.comments = action.payload.comments;
            }
        },
        // Update the response of a question
        updateResponseWithQuestionId: (state, action: PayloadAction<{ questionId: number, questionSectionId: number, questionnaireId:number, response: string }>) => {
            const uniqueKey = `${action.payload.questionnaireId}-${action.payload.questionSectionId}-${action.payload.questionId}`;
            const question = state.questions.find(question => question.uniqueKey === uniqueKey);
            if (question) {
                question.response = action.payload.response;
            }
        },
        setQuestionnaireId: (state, action: PayloadAction<{ questionId: number, questionnaireId: number }>) => {
            const questionIndex = state.questions.findIndex(question => question.questionId === action.payload.questionId);
            if (questionIndex !== -1) {
                state.questions[questionIndex].questionnaireId = action.payload.questionnaireId;
            }
        },
        // Update the unsaved changes status of all questions in a questionnaire
        updateAllUnsavedChangesWithQuestionnaireId: (state, action: PayloadAction<{ questionnaireId: number, unsavedChanges: boolean }>) => {
            state.questions.forEach(question => {
                if (question.questionnaireId === action.payload.questionnaireId) {
                    question.unsavedChanges = action.payload.unsavedChanges;
                }
            });
        },
        updateQuestionnaireName: (state, action: PayloadAction<{ questionnaireId: number, questionnaireName: string }>) => {
            state.questions.forEach(question => {
                if (question.questionnaireId === action.payload.questionnaireId) {
                    question.questionnaireName = action.payload.questionnaireName;
                }
            });
        }
    }
});
export default QuestionSlice.reducer;

export const { resetQuestions, addQuestion, updateCommentsWithQuestionId, updateResponseWithQuestionId, updateUnsavedChangesWithQuestionId, updateAllUnsavedChangesWithQuestionnaireId, updateQuestionnaireName } = QuestionSlice.actions;