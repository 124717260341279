import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationProvider } from './LocationContext';
import { VesselProvider } from './vesselContext';

interface IAppProviderProps {
  children: ReactNode;
}

interface IAppContext {
  unsavedChanges: boolean;
  setUnsavedChangesFlag: (hasChanges: boolean) => void;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const location = useLocation();

  const setUnsavedChangesFlag = (hasChanges: boolean) => {
    setUnsavedChanges(hasChanges);
  };

  // Event listener for beforeunload event to handle unsaved changes on refresh or close
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  // Reset unsavedChanges when the location changes
  useEffect(() => {
    setUnsavedChanges(false);
  }, [location.pathname]);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue: IAppContext = useMemo(() => ({
    unsavedChanges,
    setUnsavedChangesFlag,
  }), [unsavedChanges, setUnsavedChangesFlag]);

  return (
    <VesselProvider>
    <LocationProvider>
      <AppContext.Provider value={contextValue}>
        {children}
      </AppContext.Provider>
    </LocationProvider>
    </VesselProvider>
  );
};

export default AppProvider;
