import { al } from '../../auth/al-login-config';
import { McButton } from '@maersk-global/mds-react-wrapper';
import { checkIfUserLoggedIn } from '../../components/Protected/AuthHandler';
import { Navigate } from 'react-router-dom';

export const Login = () => {
    /// Check for login user
    const user = checkIfUserLoggedIn();
    if(user) {
        return <Navigate to='/dashboard' replace={true} />
    }
    /// If user not logged in
    const loginHandler = () => {
        const skipLogin = process.env.REACT_APP_NOLOGIN ? process.env.REACT_APP_NOLOGIN : '1';
        if(!parseInt(skipLogin))
        {
            al.loginRedirect({
                response_type: 'token id_token',
                entity_type: process.env.REACT_APP_ENTITY_TYPE,
                entity_code: process.env.REACT_APP_ENTITY_CODE,
                /**
                * @type: string[]
                * @default: ["openid", "profile", "email"]
                * @description: the scope of the access in the initial request
                */
                scopes: ["openid", "profile", "email"]
            });
        }
        else {
            window.location.href='https://localhost:3000/dashboard';
        }
        
        // The returned tokens are also saved in the storage. You can access them via the storage property of the al instance
        
        console.log(al.storage.access_token)
        // console outputs the value of the access_token or null
        console.log(al.storage.access_token_claims)
        // console outputs the claims of the access_token or null
        console.log(al.storage.id_token)
        // console outputs the value of the id_token or null
        console.log(al.storage.id_token_claims)
        // console outputs the claims of the id_token or null
    }
    
    return (
        <div className='container-fluid' style={{marginTop: 280, textAlign: 'center'}}>
            <div className='row'>
                <h2>Welcome to the login page!</h2>
                <h3>Please press Login button</h3>
            </div> 
            <div style={{marginBottom: 20, textAlign: 'center'}}>
                <McButton  label="Login" click={loginHandler} icon="user-circle-solid" iconposition="right"/>
            </div>
        </div>
    )
}