import './logoutPrompt.scss';
import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { McButton } from '@maersk-global/mds-react-wrapper';

const LogoutModal: React.FC<{ showModal: boolean; onClose: () => void; onConfirm: () => void }> = ({
  showModal,
  onClose,
  onConfirm
}) => {
  return (
    <Modal className='logout-modal' show={showModal} size='lg'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Confirm Logout</h5>
          <button type='button' className='btn-close' onClick={onClose}></button>
        </div>
        <div className='modal-body'>
          <p>Are you sure you want to log out?</p>
        </div>
        <div className='modal-footer'>
          <McButton slot="secondaryAction" variant="secondary" click={onClose} dialogAction="cancel">Cancel</McButton>
          <McButton slot="primaryAction" variant="primary" click={onConfirm} dialogAction="ok">Logout</McButton>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
