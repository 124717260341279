import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IRiskTab {
    riskTabId: number;
    riskTabName?: string;
    unsavedChanges: boolean;
}

interface IRiskTabState {
    riskTabs: IRiskTab[];
}

const initialState: IRiskTabState = {
    riskTabs: []
};

export const RiskTabSlice = createSlice({
    name: 'riskTabDetails',
    initialState: initialState,
    reducers: {
        resetRiskTab: () => initialState,
        addUpdateRiskTab: (state, action: PayloadAction<IRiskTab>) => {
            const existingRiskTab = state.riskTabs.find(riskTab => riskTab.riskTabId === action.payload.riskTabId);
            if (!existingRiskTab) {
                state.riskTabs.push({
                    riskTabId: action.payload.riskTabId,
                    riskTabName: action.payload.riskTabName,
                    unsavedChanges: action.payload.unsavedChanges
                });
            } else {
                existingRiskTab.riskTabName = action.payload.riskTabName;
                existingRiskTab.unsavedChanges = action.payload.unsavedChanges;
            }
        },
        updateUnsavedChangesByRiskTabId: (state, action: PayloadAction<{ riskTabId: number, unsavedChanges: boolean }>) => {
            const riskTabIndex = state.riskTabs.findIndex(riskTab => riskTab.riskTabId === action.payload.riskTabId);
            if (riskTabIndex !== -1) {
                state.riskTabs[riskTabIndex].unsavedChanges = action.payload.unsavedChanges;
            }
        }
    }
});

export default RiskTabSlice.reducer;

export const { resetRiskTab, addUpdateRiskTab, updateUnsavedChangesByRiskTabId } = RiskTabSlice.actions;