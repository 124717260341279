import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { OwnVesselApiResponse, OwnVesselListModel } from '../pages/Dashboard/models';
import { getOwnVesselList } from '../services/VesselService';

interface IVesselContextProps {
    children: ReactNode;
}

type TVesselContextValue = OwnVesselListModel[];

const VesselContext = createContext<TVesselContextValue | undefined>(undefined);

export const VesselProvider: React.FC<IVesselContextProps> = ({ children }) => {
  const [vesselList, setVesselList] = useState<OwnVesselListModel[]>([]);

  useEffect(() => {
    const fetchVesselData = async () => {
      try {
        const vesselData: OwnVesselApiResponse = await getOwnVesselList(0);
        if (vesselData) {
         setVesselList(vesselData.ownVesselSearchModelResponse.ownVesselRes);
        }
        else {
          setVesselList([]);
        }
      } catch (error) {
        setVesselList([]);
      }
    };

    fetchVesselData();
  }, []);

  return (
    <VesselContext.Provider value={vesselList}>
      {children}
    </VesselContext.Provider>
  );
};

export const useVesselData = (): TVesselContextValue => {
  const context = useContext(VesselContext);
  if (!context) {
    throw new Error('useVesselData must be used within a VesselProvider');
  }
  return context;
};
