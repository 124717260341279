import { McIcon } from '@maersk-global/mds-react-wrapper';

export type Sidenav = {
  id?: number;
  icon?: JSX.Element;
  hoverIcon?: JSX.Element;
  text?: string;
  link?: string;
  subNav: [];
};

const navData = [
  {
    id: 0,
    icon: <McIcon icon='vessel-front' color='white'></McIcon>,
    hoverIcon: <McIcon icon='vessel-front' color='#42B0D5'></McIcon>,
    text: 'Vessels',
    link: '/dashboard',
    subNav: [],
  },
  {
    id: 1,
    icon: <McIcon icon='browser' color='white'></McIcon>,
    hoverIcon: <McIcon icon='browser' color='#42B0D5'></McIcon>,
    text: 'Risks',
    link: '/risks',
    subNav: [],
  },
  {
    id: 2,
    icon: <McIcon icon='clipboard-check-text' color='white'></McIcon>,
    hoverIcon: <McIcon icon='clipboard-check-text' color='#42B0D5'></McIcon>,
    text: 'Question Library',
    link: '/question',
    subNav: [],
  },
  {
    id: 3,
    icon: <McIcon icon='book-open' color='white'></McIcon>,
    hoverIcon: <McIcon icon='book-open' color='#42B0D5'></McIcon>,
    text: 'Questionnaire',
    link: '/questionnaire',
    subNav: [
      {
        text: 'Add Questionnaire',
        link: 'questionnaire/add-questionnaire',
      },
      {
        text: 'Edit Questionnaire',
        link: 'questionnaire/edit-questionnaire',
      },
    ],
  },
  {
    id: 4,
    icon: <McIcon icon='clipboard-officer-text' color='white' data-testid='survey-type-menu-icon'></McIcon>,
    hoverIcon: <McIcon icon='clipboard-officer-text' color='#42B0D5'></McIcon>,
    text: 'Survey Types',
    link: '/survey-types',
    subNav: [
      {
        text: 'Create Inspection Type',
        link: 'survey-types/create-survey-type',
      },
      {
        text: 'Edit Inspection Type',
        link: 'survey-types/edit-survey-type',
      },
    ],
  },
];

export const getNavData = () => navData;
