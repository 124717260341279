import './unsavedPrompt.scss';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { McButton } from '@maersk-global/mds-react-wrapper';
import { useAppSelector } from '../../store/store';

interface UnsavedPromptProps {
  show: boolean;
  onHide: () => void;
  handleDiscardChanges: (discard: boolean) => void;
  isPDF?: boolean; // Prop for determining the modal content based on PDF mode
}

const UnsavedChangesModal: React.FC<UnsavedPromptProps> = ({
  show,
  onHide,
  handleDiscardChanges,
  isPDF = false,

}) => {

  const questions = useAppSelector((state) => state.questions.questions);
  const sections = useAppSelector((state) => state.sections.sections);

  // Filter sections with unsaved changes
  const unsavedSections = sections.filter(section => section.unsavedChanges && section.sectionId !== 7);

  // Filter questions with unsaved changes for section 7
  const unsavedQuestions = questions.filter(question => question.unsavedChanges);

  // Define the warning text based on the value of isPDF
  const warningText = isPDF
    ? 'You have unsaved changes. Are you sure you want to export PDF without saving them?'
    : 'You have unsaved changes. Are you sure you want to discard them?';

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop='static'
      size='lg'
      className='unsaved-changes-modal'
    >
      <Modal.Header>
        <Modal.Title>{isPDF ? 'Save Changes' : 'Confirm Discard Changes'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='warning-text'>{warningText}</p>

        {/* Table 1: Unsaved changes in all sections except section Questionnaire */}
        {unsavedSections.length > 0 && (
          <>
            <p className='section-header'>Unsaved Changes in Sections-</p>
            <div className='scrollable-table-sections'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Section Name</th>
                </tr>
              </thead>
              <tbody>
                {unsavedSections.map(section => (
                  <tr key={section.sectionId}>
                    <td>{section.sectionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </>
        )}

        {/* Table 2: Unsaved changes in Questionnaires */}
        {unsavedQuestions.length > 0 && (
          <>
            <p className='section-header'>Unsaved Changes in Questionnaires-</p>
            <div className='scrollable-table-questions'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Section/Sub-Section Name</th>
                  <th>Question ID</th>
                  <th>Question Name</th>
                </tr>
              </thead>
              <tbody>
                {unsavedQuestions.map(question => (
                  <tr key={`question-${question.questionId}`}>
                    <td>{question.questionnaireName}</td>
                    <td>{question.questionId}</td>
                    <td>{question.questionName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </>)}

      </Modal.Body>
      <Modal.Footer>
        <McButton variant='secondary' label={isPDF ? 'Export PDF' : 'Discard'} click={() => handleDiscardChanges(true)} />
        <McButton variant='primary' label={isPDF ? 'Cancel Export' : 'Keep Editing'} click={() => handleDiscardChanges(false)} />
      </Modal.Footer>
    </Modal>
  );
};

export default UnsavedChangesModal;
