import { checkIfUserLoggedIn, setUserData, keyPrefix } from "../components/Protected/AuthHandler";
import { HttpClientHandler } from '../httpHandler/HttpClientHandler';

export const loginCallbackConfig = {
 
    /**
     * @description: the uri that's whitelisted in AM for OAuth2
     */
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    /**
     * @description: Your application client id
     */
    client_id: process.env.REACT_APP_CLIENT_ID,
    /**
      * @description: The url of the authority, e.g https://accounts.maersk.com/developer
      */
    authority: process.env.REACT_APP_AUTHORITY,
    storage: {
        /**
         * @default: "localStorage"
         * @description: the type of storage you want to utilize to store the tokens
         * choices are sessionStoragesession lived tokens, and localStorage, for permanent storing (until deleted)
         */
        type: "localStorage"
    },
    /**
     * default: "iamAL"
     * this will give the AL instance name in the global scope (window.iamAL)
     */
    window_namespace: 'maerskAL',
    
    post_logout_uri: window.location.origin,

    login_redirect_cb: (data, error) => {
        //const router = getRouter();
        // adding this check to avoid dual callbacks happening
        if(!localStorage.getItem('logCount'))
        {
          localStorage.setItem('logCount', '1');
          if (data) {
            console.log('data in cb ', data);
            loginCallBack(data.access_token, data.id_token, 1);

          } else if(error && checkIfUserLoggedIn()) {
            loginCallBack(localStorage.getItem(`al.${keyPrefix}.access_token`),
                            localStorage.getItem(`al.${keyPrefix}.id_token`), 1);
          } else {
            window.location.href=`${window.origin}/error`;
          }
        }
        //getStore().dispatch("<location>", null, { root: true });
      }
}

const loginCallBack = (accessToken, idToken, status) => {
  if(status===1) {
    const back_to = localStorage.getItem("login_back_to");
    localStorage.removeItem("login_back_to");
    
    // the http client needs frJwt local storage value
    // to set Authorization header in request when needed
    localStorage.setItem("frJwt", idToken);
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({AccessToken: accessToken, IdToken: idToken})
  };
  const loginApi = `${process.env.REACT_APP_API_URL}User/login`;
  fetch(loginApi, requestOptions)
  .then((response) => response.json())
  .then((data) => {
    setUserData(data);
    window.location.href=`${window.origin}`;
  })
  .catch(error => {
    console.error('There was an error!', error);
    //window.location.href=`${window.origin}/error`;
  });
}


